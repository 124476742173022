<template>
  <div>
    <aad-title>Ask Me</aad-title>
    <div id="meetings-FAQ-search-content">
      <div class="row" id="FAQ-anchor-links-nav" v-show="this.hasResults">
        <div
          class="col-lg-4 col-sm-6"
          v-for="(category, index) in uniqueCatList"
          :key="index"
        >
          <div class="FAQ-anchor-nav-item">
            <a
              class="text-link-light-bg FAQ-anchor-nav-link"
              v-bind:href="'#' + convertToID(category)"
              @click="logCategoryClick('/ask-me#' + category)"
            >
              <h5>{{ category }}</h5>
            </a>
          </div>
        </div>
      </div>

      <div class="search-controls">
        <div class="row">
          <input
            class="search-bar-input row"
            id="meeting-faq-search-bar"
            type="text"
            placeholder="Search FAQs"
            name="search"
            v-model="searchTerm"
            v-on:keyup.enter="searchFAQs()"
          />
        </div>
        <div class="row">
          <button
            class="btn btn-primary-outline"
            id="meeting-faq-clear-btn"
            type="submit"
            v-on:click="clearSearch()"
          >
            Clear
          </button>
          <button
            class="btn btn-primary-solid"
            id="meeting-faq-search-btn"
            type="submit"
            v-on:click="searchFAQs()"
          >
            Search
          </button>
        </div>
        <h3 class="no-search-results" v-show="!this.hasResults">
          No results found. Please try another search term.
        </h3>
      </div>

      <div id="FAQs-list" v-show="this.hasResults">
        <div
          class="FAQ-cat-section"
          v-for="category in uniqueCatList"
          :key="category"
        >
          <span
            class="anchor-link-buffer"
            v-bind:id="convertToID(category)"
          ></span>
          <h2 class="FAQ-search-topic-header">{{ category }}</h2>
          <div class="accordion">
            <div
              class="accordion-item"
              v-for="item in categoryItems(category)"
              :key="item.Name"
            >
              <div
                class="row accordion-item-header"
                v-on:click="toggleAccordion($event)"
              >
                <h5 class="col">{{ item.Name }}</h5>
                <aad-icon
                  name="caret-right-light"
                  class="accordion-caret"
                ></aad-icon>
              </div>
              <div
                class="accordion-item-content"
                v-html="convertAnswerMarkdown(item)"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import showdown from "showdown";
import Analytics from "@/mixins/analytics.js";

export default {
  data() {
    return {
      FAQList: [],
      hasResults: true,
      displaySearchResults: false,
      searchResults: [],
      searchTerm: "",
    };
  },
  mounted() {
    this.getAllFaqs();
  },
  metaInfo: {
    title: "Ask Me",
  },
  methods: {
    getAllFaqs() {
      this.$store.dispatch("loadFaqs");
      this.FAQList = this.$store.faqs;
    },
    convertToID: function (topicName) {
      var topicID = (topicID = topicName.split(" ").join("-").toLowerCase());

      return topicID;
    },
    categoryItems: function (category) {
      var faqs;

      if (this.displaySearchResults) {
        faqs = this.searchResults;
      } else {
        faqs = this.allFaqs;
      }

      let itemsInCat = [];

      for (var i = 0; i < faqs.length; i++) {
        if (faqs[i].Topics.includes(category)) {
          itemsInCat.push(faqs[i]);
        }
      }
      return itemsInCat;
    },
    convertAnswerMarkdown: function (FAQ) {
      var converter = new showdown.Converter(),
        html = converter.makeHtml(FAQ.Description);

      return html;
    },
    toggleAccordion: function (event) {
      var accordionHeader = this.getAccordionHeader(event.target);

      if (
        accordionHeader.parentElement.classList.contains("closed") ||
        (!accordionHeader.parentElement.classList.contains("open") &&
          !accordionHeader.parentElement.classList.contains("closed"))
      ) {
        this.openAccordion(accordionHeader.nextElementSibling);
      } else {
        this.closeAccordion(accordionHeader.nextElementSibling);
      }

      const header5 = accordionHeader.getElementsByTagName("h5")[0];

      console.log(header5);

      // Log to analytics
      Analytics.logEvent("aad_ask_me_click", {
        search_term: this.searchTerm,
        faq_name: header5.innerText,
      });
    },
    logCategoryClick(url) {
      console.log(url);
      Analytics.logLinkClick("ask-me-category", url, "ask_me");
    },
    closeAccordion: function (accordionItem) {
      accordionItem.parentElement.classList.toggle("open");
      accordionItem.parentElement.classList.toggle("closed");
      accordionItem.style.maxHeight = null;
    },
    openAccordion: function (accordionItem) {
      accordionItem.parentElement.classList.toggle("open");
      if (accordionItem.parentElement.classList.contains("closed")) {
        accordionItem.parentElement.classList.toggle("closed");
      }
      accordionItem.style.maxHeight = accordionItem.scrollHeight + "px";
    },
    getAccordionHeader: function (accordionElem) {
      while (!accordionElem.classList.contains("accordion-item-header")) {
        accordionElem = accordionElem.parentElement;
      }

      return accordionElem;
    },
    searchFAQs: function () {
      this.searchResults = [];

      for (var i = 0; i < this.allFaqs.length; i++) {
        if (
          this.allFaqs[i].Name.toLowerCase().includes(
            this.searchTerm.toLowerCase()
          ) ||
          this.allFaqs[i].Description.toLowerCase().includes(
            this.searchTerm.toLowerCase()
          )
        ) {
          this.searchResults.push(this.allFaqs[i]);
        }
      }

      if (this.searchResults.length > 0) {
        this.hasResults = true;
        this.displaySearchResults = true;
      } else {
        this.hasResults = false;
      }
    },
    clearSearch: function () {
      this.hasResults = true;
      this.displaySearchResults = false;
      this.searchTerm = "";
      this.searchResults = [];
    },
  },
  computed: {
    ...mapGetters(["allFaqs"]),
    uniqueCatList: function () {
      const categories = [];
      let faqs;

      if (this.displaySearchResults) {
        faqs = this.searchResults;
      } else {
        faqs = this.allFaqs;
      }

      for (var i = 0; i < faqs.length; i++) {
        for (var j = 0; j < faqs[i].Topics.length; j++) {
          if (!categories.includes(faqs[i].Topics[j])) {
            categories.push(faqs[i].Topics[j]);
          }
        }
      }

      return categories;
    },
  },
};
</script>

<style scoped>
.FAQ-search-topic-header {
  margin-bottom: 0;
}

.no-search-results {
  margin: 12px 0;
}

.FAQ-cat-section {
  padding-top: 24px;
  position: relative;
}

.FAQ-cat-section .accordion {
  margin-bottom: 12px;
}

.search-controls {
  width: 75%;
}

#meeting-faq-search-bar {
  border: solid 1px #1c7cd5;
  border-radius: 4px;
  padding: 5px;
  margin: 0;
  outline: none;
  height: 53px;
  width: 100%;
  margin-bottom: 24px;
}

#meeting-faq-clear-btn {
  margin-right: 12px;
}

#FAQ-anchor-links-nav {
  margin-bottom: 24px;
}

.FAQ-anchor-nav-item {
  margin: 0 8px;
  padding: 12px;
  border-bottom: 1px solid #c1cbdc;
}

.FAQ-anchor-nav-link {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 550; /*$font-weight-semibold*/
}

.FAQ-anchor-nav-link h5 {
  color: inherit;
}

.anchor-link-buffer {
  position: absolute;
  top: -80px;
}

@media screen and (max-width: 1200px) {
  .anchor-link-buffer {
    top: -50px;
  }
}

@media screen and (max-width: 992px) {
  .search-controls {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .FAQ-anchor-nav-item {
    padding: 8px;
  }

  .FAQ-cat-section {
    padding-top: 12px;
  }
}
</style>
